/************************************************
 * IMPORTS
 ***********************************************/
import {createStore, applyMiddleware, combineReducers} from 'redux'
import thunk from 'redux-thunk'

import user from './user'

/************************************************
 * REDUCERS
 ***********************************************/

const reducres = {
	user
}
 
/************************************************
 * STORE
 ***********************************************/
 
export default createStore(
	combineReducers(reducres),
	applyMiddleware(thunk)
)