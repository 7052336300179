import React from 'react'

export default function Roadmap(props) {

	return(
		<div id="roadmap">
			
			<h1 className='title'>Development roadmap</h1>

			<p className='info text-muted'>
				TOGETHER WE WILL BUILD A BETTER WORLD WHERE WE WILL BE ABLE TO LIVE FREE. AND THE FIRST STEP IS TO 
				BUILD THE ARENA WHERE YOUR BALDY WILL FIGHT WITH THE OTHERS IN THE SOLO, TEAM, TOURNAMENT, OR BATTLE 
				ROYAL MODES. LET’S MAKE A BIG NOISE AROUND AND WIN SOME CRYPTO FROM EACH VICTORY! OUR BALDY's WERE 
				BORN TO FIGHT, SO DON’T LET THEM WAIT TO SHOW WHO IS THE REAL CHAMPION HERE.
			</p>

			<div className='steps'>



				<div className='step'>

					<div className='path-wrapper'>

						<div className='dot'/>

						<div className='path'/>

					</div>	

					<div className='box'>

						<h1 className='sub-title'><span className='outline'>25%</span>SALES</h1>

						<p className='info text-muted'>
							THE BALDY’ SECRET GOVERNMENT WILL GIVE AWAY HALF OF THE AMOUNT EARNED FROM THE SECONDARY SALES ON OPENSEA 
							AS A SPECIAL BONUS FOR THE FIGHTERS TO KEEP THEM MOTIVATED AND RESPECT THE COMMUNITY ON THIS LONG WAY.
						</p>
						
					</div>

				</div>



				<div className='step'>

					<div className='path-wrapper'>

						<div className='dot'/>

						<div className='path'/>

					</div>	

					<div className='box'>

						<h1 className='sub-title'><span className='outline'>50%</span>SALES</h1>

						<p className='info text-muted'>
							THE BALDY’ SECRET GOVERNMENT WILL GIVEAWAY TO THE COMMUNITY MEMBERS A $10,000 REWARD 
							AS A SPECIAL GIFT FOR THE COMMUNITY TO PREPARE THEIR BALDY FOR THE TOURNAMENT AND OTHER FUTURE EVENTS.
						</p>
						
					</div>

				</div>



				<div className='step'>

					<div className='path-wrapper'>

						<div className='dot'/>

						<div className='path'/>

					</div>	

					<div className='box'>

						<h1 className='sub-title'><span className='outline'>75%</span>SALES</h1>

						<p className='info text-muted'>
							THE BALDY’ SECRET GOVERNMENT WILL GIFT 10 NON-FUNGIBLE TOKENS AND SOME OTHER UNIQUE TRAITS TO THE BALDY’ 
							OWNERS AND COMMUNITY MEMBERS TO BOOST THEIR BALDY's AND INCREASE THE CHANCES TO WIN THE COMPETITION.
						</p>
						
					</div>

				</div>



				<div className='step'>

					<div className='path-wrapper'>

						<div className='dot'/>

					</div>	

					<div className='box'>

						<h1 className='sub-title'><span className='outline'>100%</span>SALES</h1>

						<p className='info text-muted'>
							THE BALDY’ SECRET GOVERNMENT WILL RUN A BIG TOURNAMENT TO DEFINE WHO IS THE BALDY 
							OF THE YEAR AND REWARD THE BEST FIGHTER WITH THE PRIZE OF $50,000 AND AN ANNUAL SUPPLY 
							OF FOOD FOR BETTER RECOVERY.
						</p>
						
					</div>

				</div>



			</div>

		</div>
	)

}