import React from 'react'
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

export default function Faq(props) {

	return(
		<div id="faq">

			<h1 className='title'>FREQUENTLY ASKED QUESTIONS</h1>

			<p className='info text-muted'>
				WE TRIED TO DESCRIBE EVERYTHING IN THE BEST WAY POSSIBLE, BUT IF YOU HAVE ANY 
				QUESTIONS REGARDING THE PROJECT OR YOU MAYBE WANT TO CLARIFY SOMETHING THEN 
				THESE ANSWERS ON FREQUENTLY ASKED QUESTIONS ARE RIGHT FOR YOU.
			</p>


			<div className='questions'>

				<Accordion
					allowZeroExpanded
					allowMultipleExpanded
				>


					<AccordionItem>

						<AccordionItemHeading>
								<AccordionItemButton>
									WHAT IS BALDY CLUB?
								</AccordionItemButton>
						</AccordionItemHeading>

						<AccordionItemPanel>
								<p>
									Baldy’ Club is a collection of 10,000 unique NFTs which grant you access to a play-to-earn game, 
									in which the best fighters are rewarded each week with cash prizes over $3,000 while having fun! 
									Only Baldy holders can play the game, and have real fun while earning cash prizes without having 
									to farm on a point and click for hours! There’s more to come in our #roadmap channel!
								</p>
						</AccordionItemPanel>

					</AccordionItem>


					<AccordionItem>

						<AccordionItemHeading>
								<AccordionItemButton>
									HOW MANY BALDY ARE IN THE CLUB?
								</AccordionItemButton>
						</AccordionItemHeading>

						<AccordionItemPanel>
								<p>
									There are only 10,000 baldy in our club. We decided to limit access to the fight club to make each baldy 
									unique and valuable. Probably we will have to join more baldy in the club within some time, but only in 
									case if our community will beg us to do that.
								</p>
						</AccordionItemPanel>

					</AccordionItem>


					<AccordionItem>

						<AccordionItemHeading>
								<AccordionItemButton>
									WHAT IS THE COST OF EACH BALDY?
								</AccordionItemButton>
						</AccordionItemHeading>

						<AccordionItemPanel>
								<p>
									Our main goal is to build a great community and grow the baldy’ world, so we decided to make this collection 
									affordable for every crypto investor. Our sale offers you to mint each baldy for the basic price of 0.04 Ethers. 
									The initial sale limit is only 3 baldy for the holder.
								</p>
						</AccordionItemPanel>

					</AccordionItem>



					<AccordionItem>

						<AccordionItemHeading>
								<AccordionItemButton>
									WHEN CAN I MINT MY BALDY?
								</AccordionItemButton>
						</AccordionItemHeading>

						<AccordionItemPanel>
								<p>
									You have a few options to buy a fighter for your collection. If you are a whitelisted investor you could join our presale that 
									starts 1st of February. For other investors, the sale date starts 3rd of February. The last chance to buy a baldy is a secondary 
									sale that takes place on OpenSea.
								</p>
						</AccordionItemPanel>

					</AccordionItem>



					<AccordionItem>

						<AccordionItemHeading>
								<AccordionItemButton>
									WHEN WILL BALDY BE REVEALED?
								</AccordionItemButton>
						</AccordionItemHeading>

						<AccordionItemPanel>
								<p>
									Reveal will happen 24h after the public sale begins! Hopefully, you can mint one of our 100 legendary NFTs.
								</p>
						</AccordionItemPanel>

					</AccordionItem>


					<AccordionItem>

						<AccordionItemHeading>
								<AccordionItemButton>
									WHERE CAN I FIND MY BALDY?
								</AccordionItemButton>
						</AccordionItemHeading>

						<AccordionItemPanel>
								<p>
									Once you have minted your Baldy, you can access it on your profile page on our website. Another way to see it is through 
									OpenSea’s website. No matter what option you chose, you’ll need to connect your MetaMask wallet to the website.
								</p>
						</AccordionItemPanel>

					</AccordionItem>



					<AccordionItem>

						<AccordionItemHeading>
								<AccordionItemButton>
									WHAT IS THE SECONDARY SALE ROYALTY?
								</AccordionItemButton>
						</AccordionItemHeading>

						<AccordionItemPanel>
								<p>
									Our secondary sale market is OpenSea, which takes 2,5% from each sale you made on it. Our 
									club royalty from each sale is 10% that we will spend on project development. At least half 
									of the royalties amount we will spend on the battle rewards, while the other half on the 
									marketing activities to maintain the club’s growth.
								</p>
						</AccordionItemPanel>

					</AccordionItem>


					<AccordionItem>

						<AccordionItemHeading>
								<AccordionItemButton>
									WHAT GAME MODES WILL BE AVAILABLE?
								</AccordionItemButton>
						</AccordionItemHeading>

						<AccordionItemPanel>
								<p>
									We’re already working on several game modes! You’ll be able to play a 1v1 mode, 
									a team fight mode, and a battle royale. We also have prepared a tournament mode 
									in full animation (so not exactly playable), which will be available right after 
									the NFT sale ends. Unlike other P2Es, Baldy’ Club will be an actual game, and not 
									a point-and-click farm! Gameplay is what matters to us, our priority is to make a 
									fun game (and yet our rewards kick ass).
								</p>
						</AccordionItemPanel>

					</AccordionItem>



					<AccordionItem>

						<AccordionItemHeading>
								<AccordionItemButton>
									WHEN WILL THESE GAME MODS BE AVAILABLE?
								</AccordionItemButton>
						</AccordionItemHeading>

						<AccordionItemPanel>
								<p>
									Auto animated tournament mode: right after the sale ends. 1v1 and multiplayer team fight modes: Mid Q1 2022. 
									Battle Royale mode: late Q2/early Q3 2022. The beta test version will be released between sale and launch! 
									Before that, the first game mode will be available for you all to test and train your Baldy!
								</p>
						</AccordionItemPanel>

					</AccordionItem>




					<AccordionItem>

						<AccordionItemHeading>
								<AccordionItemButton>
									HOW DO CASH PRIZES WORK?
								</AccordionItemButton>
						</AccordionItemHeading>

						<AccordionItemPanel>
								<p>
									At least 150 000$ will be given throughout the year 2022, which corresponds to 3000$ a week. Then, we’ll 
									reinject 50% of secondary market royalties into our cash prize bag, with no limit.
								</p>
						</AccordionItemPanel>

					</AccordionItem>




					<AccordionItem>

						<AccordionItemHeading>
								<AccordionItemButton>
									WHAT CAN I DO WITH MY BALDY?
								</AccordionItemButton>
						</AccordionItemHeading>

						<AccordionItemPanel>
								<p>
									First of all, you can battle against other baldy and earn cash prizes. Besides that, you can do whatever you want with the 
									Baldy you minted, as you own them accordingly to intellectual property laws! You can also use them as an investment, but it 
									would be such a waste not to play the best P2E game out there!
								</p>
						</AccordionItemPanel>

					</AccordionItem>



						
				</Accordion>

			</div>

		</div>
	)

}