import React from 'react'

export default function About(props) {

	return(
		<div id="about">

			<h2 className='title'>Welcome to the club</h2>

			<p className='info text-muted'>
				ONCE UPON A TIME, PEOPLE LIVED IN A BEAUTIFUL PEACE. BUT THAT ALL CHANGED WHEN THE PEOPLE STARTED FIGHTING FOR POWER. 
				ONLY THE STRONG ONES, WHO WERE ABLE TO FIGHT, COULD STOP THE WAR. OVER TIME, THE THIRSTY PEOPLE FOR POWER WERE DEFEATED, 
				BUT THE DESIRE TO FIGHT WAS STILL STRONG. TO AVOID ANOTHER WAR, THE SURVIVIORS’ SECRET GOVERNMENT DECIDED TO ESTABLISH A 
				TOURNAMENT TO LET THE BRAVEST FIGHT AND CARVE THEIR NAMES IN THE AGES! AND SO THE BALDY CLUB WAS BORN. THERE ARE ONLY 
				THREE SIMPLE RULES IN THE CLUB. IT IS HIGHLY RECOMMENDED TO READ THEM CAREFULLY AND REMEMBER THEM WELL TO AVOID AWKWARD 
				SITUATIONS AND DECREASE THE CHANCES TO LEAVE THE CLUB WITH A BROKEN BACK. WE ARE DECENT SURVIVORS, NOT SOME HOOLIGANS 
				THIRSTY FOR POWER! REMEMBER THAT!
			</p>


			<div className='rule-group'>

				<div className='rule-img'>
					<img src='assets/img/5.png' className='flip-img' alt='' />
					<div className='shadow' />
				</div>

				<div className='rule-info'>
					<h2 className='title'>RULE <span className='outline'>01</span></h2>
					<p className='info text-muted'>
						FIRST RULE OF THE BALDY CLUB: YOU DO NEVER TALK ABOUT THE CLUB. NO, WE ARE SERIOUS, KEEP YOUR MOUTH CLOSED! 
						PEOPLE THINK THAT WE ARE CUTE AND FUNNY. THEY BELIEVE THAT ALIENS AND REPTILIANS RULE THE WORLD. LET IT BE!
					</p>
				</div>

			</div>


			<div className='rule-group'>

				<div className='rule-info '>
					<h2 className='title'>RULE <span className='outline'>02</span></h2>
					<p className='info text-muted'>
						SECOND RULE OF THE BALDY CLUB: YOU MUST BE POLITE WITH OTHER MEMBERS OF THE CLUB. OUR COMMUNITY RESPECTS 
						PEOPLE AND PEOPLE OF ANY KIND. SO SHUT UP YOUR MOUTH AND BE A GENTLE BALDY!
					</p>
				</div>

				<div className='rule-img right'>
					<img src='assets/img/5.png' alt='' />
					<div className='shadow' />
				</div>


			</div>


			<div className='rule-group'>

				<div className='rule-img'>
					<img src='assets/img/5.png'  className='flip-img' alt='' />
					<div className='shadow' />
				</div>

				<div className='rule-info'>
					<h2 className='title'>RULE <span className='outline'>03</span></h2>
					<p className='info text-muted'>
						THIRD RULE OF THE BALDY CLUB: THERE ARE NO OTHER RULES IN THE  CLUB. DO WHATEVER YOU WANT. 
						FIGHT WHOMEVER YOU WANT TO FIGHT. WE ARE HERE TO HAVE SOME FUN. LET’S CELEBRATE AND BREAK SOMEONE’S BACK!
					</p>
				</div>

			</div>


			<h2 className='title'>OUR STRATEGY</h2>

			<p className='info text-muted'>
				WE PUT A LOT OF EFFORT INTO THIS PROJECT AND WE BELIEVE THAT OUR CRYPTO COMMUNITY AND INVESTORS APPRECIATE 
				ALL CURRENT ACHIEVEMENTS. NEVERTHELESS, WE STILL HAVE A LONG WAY TO GO AND WE WANT TO PASS IT TOGETHER. WE 
				CERTAINLY HOPE YOU WILL JOIN US SOONER OR LATER, BUT... BEFORE THAT, WE ARE HAPPY TO SHARE OUR DREAMS AND 
				IDEAS THAT FORM OUR VISION AND STRATEGY FOR THIS PROJECT.
			</p>


			<div className='strategies'>

					<div className='strategies-block'>

						<h2 className='sub-title'>MARKETING</h2>
			
						<div className='info text-muted'>
							WE INVESTED ABOUT $75,000 IN MARKETING ACTIVITIES AND COOPERATE A LOT WITH DESIGNERS, COPYWRITERS, MARKETERS, 
							AND INFLUENCERS TO MAKE THIS PROJECT ATTRACTIVE AND AROUSE INTEREST IN THE COMMUNITY.
						</div>

					</div>

					
	

				<div className='strategies-block'>

					<h2 className='sub-title'>Community</h2>
		
					<div className='info text-muted'>
						OUR COMMUNITY MEANS EVERYTHING TO US AND WE WANT TO BUILD THE FUTURE TOGETHER. ALL IMPORTANT DECISIONS IN 
						OUR WORLD WILL BE MADE BY JOINT VOTING.
					</div>

				</div>


				<div className='strategies-block'>

					<h2 className='sub-title'>Development</h2>
				
					<div className='info text-muted'>
						THE GOAL WE WILL ACHIEVE TOGETHER IS TO BUILD AN EARN-TO-PLAY ONLINE GAME WHERE ALL SURVIVORS WILL BE ABLE TO FIGHT 
						AND WIN CRYPTO REWARDS AS WELL AS UNIQUE ITEMS TO LEARN NEW SKILLS AND EVOLVE.
					</div>

				</div>


				<div className='strategies-block'>

					<h2 className='sub-title'>Conference</h2>
			
					<div className='info text-muted'>
						WE WILL ORGANIZE A BIG PARTY AFTER ALL SURVIVORS WILL FIND THEIR HOME. WE WILL SHARE THE DEVELOPMENT PLANS, 
						INCREDIBLE IDEAS, AND FUTURE VISION OF THE PROJECT, BUT THE FIRST POINT IS TO GET DRUNK!
					</div>

				</div>


				<div className='strategies-block'>

					<h2 className='sub-title'>DEVELOPMENT OF P2E</h2>
			
					<div className='info text-muted'>
						WE DEVELOPED A FUN PLAY-TO-EARN GAME IN WHICH YOU CAN CHALLENGE OTHER BALDY OWNERS AND TRY TO WIN MASSIVE CASH PRIZES, 
						THROUGH SEVERAL GAME MODES. YOU’LL BE ABLE TO PLAY A 1V1 MODE, A TEAM FIGHT MODE, AND A BATTLE ROYALE. WE ALSO HAVE 
						PREPARED A TOURNAMENT MODE IN FULL ANIMATION (SO NOT EXACTLY PLAYABLE), WHICH WILL BE AVAILABLE RIGHT AFTER THE NFT 
						SALE ENDS. THE FIRST REALLY PLAYABLE MODE WILL BE RELEASED DURING Q1 2022. WE OBVIOUSLY THOUGHT OF CREATING A BALANCED 
						SYSTEM, TO ENSURE NO BALDY IS STRONGER THAN ANOTHER, REGARDLESS OF MINT COST. SKILL IS WHAT WILL DETERMINE THE WINNER! 
						THIS MODE WON’T BE TURN-BASED, IT’S GONNA BE LIVE AND EVERY FIGHTER WILL BE ABLE TO INTERACT IN REAL-TIME, TO DESTROY 
						HIS ENEMIES. OUR TEAM IS ALREADY MOVING ON TO THIS FIRST GAME MODE AND WE EXPECT A BETA TEST TO BE RELEASED BEFORE LAUNCH. 
						THE GAME WON’T REQUIRE TRYHARD: THERE WILL BE A LIMIT OF WEEKLY FIGHTS TO BE REGISTERED ON OUR RANKING, EVERY OTHER GAME 
						YOU’LL PLAY WILL BE FOR FUN! THIS WAY, WE MAKE SURE EVERY PLAYER GETS A SHOT AT WINNING A CASH PRIZE.
					</div>

				</div>


				<div className='strategies-block'>

					<h2 className='sub-title'>CASH PRIZES SYSTEM</h2>
			
					<div className='info text-muted'>
						WE WILL REWARD OUR PLAYERS THE BEST WE CAN WITH AN ATTRACTIVE AND GROWING CASH PRIZE SYSTEM. WE GUARANTEE THAT OVER $150,000 
						WILL BE GIVEN THROUGHOUT 2022, WHICH CORRESPONDS TO ABOUT $3,000 A WEEK. THEN, WE WILL ADD 50% OF OUR SECONDARY MARKET ROYALTIES 
						EACH WEEK TO KEEP INCREASING THE CASH PRIZES, WITH NO LIMIT UP! THEREFORE, THE MORE VOLUME WE GET ON OPENSEA, THE MORE REWARDS PLAYERS GET!
					</div>

				</div>

			</div>

		</div>
	)

}