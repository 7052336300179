import React from 'react'

import Header from './Sections/Header'
import About from './Sections/About'
import Carousel from './Sections/Carousel'
import Roadmap from './Sections/Raodmap'
import Team from './Sections/Team'
import Faq from './Sections/Faq'



export default function Home(props) {

	// const dispatch = useDispatch()
	// const user = useSelector(state => state.user)
	// const [mintAmount, setMintAmount] = useState(1)
	// const [end, setEnd] = useState(false)

	// const onMint = () => dispatch(mintNFT(mintAmount))

	// const limit = user.onlyWhitelisted ? user.nftPerAddressLimit : user.maxMintAmountPerTx

	return(
		<div className='home-page'>


			<Header />

			<About />

			<Carousel />

			<Roadmap />

			<Team/>

			<Faq />


		</div>
	)

}