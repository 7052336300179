import React from 'react'
import OwlCarousel from 'react-owl-carousel-rtl'


export default function Carousel(props) {
	
	return(
		<div className='carosels'>
			
			<OwlCarousel 
				className='owl-theme' 
				loop 
				margin={20} 
				autoplay 
				autoplayTimeout={5000}
				smartSpeed={8000}
				mouseDrag={false}
				touchDrag={false}
				pullDrag={false}
				freeDrag={false}
				nav={false}
				dots={false}
				responsive = {{
					0 : {
							items : 3
					},
					768 : {
						items : 4
					}
				}}
			>
				<div className='item'>
					<img src="/assets/carousel/1.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/2.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/3.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/4.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/5.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/6.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/7.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/8.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/9.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/10.png" alt="" className="owl-nft" />
				</div>

			</OwlCarousel>


			<OwlCarousel 
				className='owl-theme  owl-rtl' 
				rtl={true}
				loop 
				margin={20} 
				autoplay 
				autoplayTimeout={5000}
				smartSpeed={8000}
				mouseDrag={false}
				touchDrag={false}
				pullDrag={false}
				freeDrag={false}
				nav={false}
				dots={false}
				responsive = {{
					0 : {
							items : 3
					},
					768 : {
						items : 4
					}
				}}
			>
				<div className='item'>
					<img src="/assets/carousel/11.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/12.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/13.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/14.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/15.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/16.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/17.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/18.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/19.png" alt="" className="owl-nft" />
				</div>
				<div className='item'>
					<img src="/assets/carousel/20.png" alt="" className="owl-nft" />
				</div>

			</OwlCarousel>

				

		</div>	
	)

}