/************************************************
 * IMPORTS
 ***********************************************/

import {createBrowserHistory} from 'history' 
import {toast} from 'react-toastify'


/************************************************
* Create browser history
***********************************************/

export const history = createBrowserHistory()

/************************************************
* Format number
***********************************************/

export const formatNumber = number => {

	var value = (number).toLocaleString(
		undefined, // leave undefined to use the visitor's browser 
							 // locale or a string like 'en-US' to override it.
		{ minimumFractionDigits: 0 }
	);
	
	return value

}



/************************************************
* Short wallet hash
***********************************************/
export const formatHash  = (str, size = 4) => {

	if (!str.length || str.length < 10)
		return 'test'

	return `${str.slice(0, size + 1)}....${str.slice(str.length - (size + 1), str.length - 1)}`

} 


/************************************************
* Method displaying promomise message
***********************************************/

export const showPromiseMessage = (func, success = 'Succesfuly', error ='Oops, something wrong', pending = 'Loading, please wait...') => {

	toast.promise(
		func,
		{
			
			pending: pending,
			success:  {
				render({data}) {
	
					if (!data)
						return false
	
					return <a style={{color: '#fff'}} target="_blank"  rel="noreferrer" href={`https://rinkeby.etherscan.io/tx/${data.transactionHash}`}>
						View transaction:	{formatHash(data.transactionHash)}
						</a>
				}
			},
			pauseOnFocusLoss: true,
			draggable: true,
			pauseOnHover: true,
			theme:'dark',
			error: {
				render({data}){
					return data || error
				}
			}
		}
	)
	
	return null
	
}


/************************************************
* Method displaying message
***********************************************/

export const showMessage = (message, type = 'error') => {

	toast[type](message, {
		autoClose: 2500,
		draggable: false,
		closeOnClick: true,
		pauseOnHover: true
	})
	
	return null
	
}
