import React from 'react'
import {TWITTER_URL, DISCORD_URL} from '../../constants'

export default function Navbar(props) {

	const onScrollTo = to => window.document.getElementById(to).scrollIntoView()



	return (
		<section className="navbar">
			<div className="max desktop">

				<h3>Baldy NFT</h3>

				<div className="separator" />

				<div className='buttons'>

					<button onClick={() => onScrollTo('about')}>
						about
					</button>

					<button onClick={() => onScrollTo('roadmap')}>
						roadmap
					</button>

					<button onClick={() => onScrollTo('team')}>
						team
					</button>

					<button onClick={() => onScrollTo('faq')}>
						faq
					</button>

				</div>

				<div className="separator" />

				<div className='group'>

					<div className="socials">
						<a href={TWITTER_URL} target="_blank"  rel="noreferrer"><i className="fab fa-twitter"/> </a>
						<a href={DISCORD_URL} target="_blank"  rel="noreferrer"><i className="fab fa-discord"/> </a>
					</div>

					{/* <button className='btn btn-connect' onClick={onConnect}>
						{user.coinbase ? formatHash(user.coinbase) : 'Connect'}
					</button> */}

				</div>
				
			</div>
			
			<div className='max mobil'>

				<div className='buttons'>

					<button onClick={() => onScrollTo('about')}>
						about
					</button>

					<button onClick={() => onScrollTo('roadmap')}>
						roadmap
					</button>

					<button onClick={() => onScrollTo('team')}>
						team
					</button>

					<button onClick={() => onScrollTo('faq')}>
						faq
					</button>

				</div>

			</div>

		</section>
	)

}