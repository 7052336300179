import React from 'react'

export default function Team(props) {

	return(
		<div id='team'>

			<h1 className='title'>BALDY’ SECRET GOVERNMENT</h1>

			<p className='info text-muted'>
				NOW, AS YOU KNOW ALL THE PLANS WE HAVE, IT IS THE RIGHT TIME TO INTRODUCE OUR SECRET 
				GOVERNMENT TO YOU. MAYBE, IT IS NO SECRET GOVERNMENT ANYMORE. ANYWAY, PLEASE MEET THE 
				BALDY THAT LOVE NUTS SO MUCH, SO THEY FAILED THE BIGGEST CHALLENGE OF NO NUT NOVEMBER.
			</p>

		</div>
	)

}