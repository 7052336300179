/************************************************
 * IMPORTS
 ***********************************************/

import React,{ useEffect } from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {Provider} from 'react-redux'
import {ToastContainer} from 'react-toastify'
import store from './reducers'
import {useDispatch} from 'react-redux'

import { 
	detectChain
} from './actions/user'

/************************************************
* STYLES
***********************************************/

import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import 'react-toastify/dist/ReactToastify.min.css'
import 'react-accessible-accordion/dist/fancy-example.css'


/************************************************
* COMPONENTS
***********************************************/

import Home from './components/Home'
// import ComingSoon from './components/ComingSoon'

import Navbar from './components/Navbar'
import Footer from './components/Footer'


/************************************************
* INIT
***********************************************/



const App = () => {

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(detectChain())
	}, [dispatch])

	return (
		<>
			<Navbar />

			<Routes>
				<Route  path='/' element={<Home/>} />
			</Routes>

			<Footer />

		</>
	)
}



/************************************************
* Render the application
***********************************************/

ReactDOM.render(
	<BrowserRouter>
		<Provider store={store}>
			<App />
			<ToastContainer theme="dark"/>
		</Provider>
	</BrowserRouter>,
	document.getElementById('app')
)