export const TITLE = 'Baldy NFT'
export const DOMAIN = 'baldynft.com'

export const USER_UPDATE			= 'USER_UPDATE'

// MAINET
export const CONTRACT_ADDRESS ='0x716d19ce79538b2EC056271043ae4CAee480F272'

// TESTNET
// export const CONTRACT_ADDRESS ='0xCcBbCEbF1F750b68e4C2a167bF8FBe942FF5838A'

export const DISCORD_URL = 'https://discord.gg/VWkfNkmN9C'
export const TWITTER_URL = 'https://twitter.com/BaldyNft'


export const ALLOWED_NETWORKS = [`0x${Number(4).toString(16)}`, `0x${Number(1).toString(16)}`]

export const NETWORKS = {
	bsc_testnet: {
		chainName: "Binance Smart Chain Testnet",
		chainId: `0x${Number(97).toString(16)}`,
		nativeCurrency: {
			name: "Binance Chain Native Token",
			symbol: "tBNB",
			decimals: 18
		},
		rpcUrls: [
			"https://data-seed-prebsc-1-s1.binance.org:8545",
			"https://data-seed-prebsc-2-s1.binance.org:8545",
			"https://data-seed-prebsc-1-s2.binance.org:8545",
			"https://data-seed-prebsc-2-s2.binance.org:8545",
			"https://data-seed-prebsc-1-s3.binance.org:8545",
			"https://data-seed-prebsc-2-s3.binance.org:8545"
		],
		blockExplorerUrls: ['https://testnet.bscscan.com']
	},

	bsc: {
		chainName: "Binance Smart Chain",
		chainId: `0x${Number(56).toString(16)}`,
		nativeCurrency: {
			name: "Binance Chain Native Token",
			symbol: "BNB",
			decimals: 18
		},
		rpcUrls: [
			"https://bsc-dataseed1.binance.org",
			"https://bsc-dataseed2.binance.org",
			"https://bsc-dataseed3.binance.org",
			"https://bsc-dataseed4.binance.org",
			"https://bsc-dataseed1.defibit.io",
			"https://bsc-dataseed2.defibit.io",
			"https://bsc-dataseed3.defibit.io",
			"https://bsc-dataseed4.defibit.io",
			"https://bsc-dataseed1.ninicoin.io",
			"https://bsc-dataseed2.ninicoin.io",
			"https://bsc-dataseed3.ninicoin.io",
			"https://bsc-dataseed4.ninicoin.io",
			"wss://bsc-ws-node.nariox.org"
		],
		blockExplorerUrls: ['https://bscscan.com']	
	},
	rinkeby: {
		chainName: "Rinkeby Test Network",
		chainId: `0x${Number(4).toString(16)}`,
		nativeCurrency: {
			name: "Rinkeby Ether",
			symbol: "RIN",
			decimals: 18
		},
		rpcUrls: [
			"https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
			"wss://rinkeby.infura.io/ws/v3/9aa3d95b3bc440fa88ea12eaa4456161"
		],
		blockExplorerUrls: ['https://rinkeby.etherscan.io']
	},

	eth: {
		chainName: "Ethereum Mainnet",
		chainId: `0x${Number(1).toString(16)}`,
		chain: "ETH",
		nativeCurrency: {
			name: "Ether",
			symbol: "ETH",
			decimals: 18
		},
		rpcUrls: [
			"https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
			"wss://mainnet.infura.io/ws/v3/9aa3d95b3bc440fa88ea12eaa4456161",
			"https://api.mycryptoapi.com/eth",
			"https://cloudflare-eth.com"
		],
		blockExplorerUrls: ['https://etherscan.io']
	},

}