import {
	USER_UPDATE
} from '../constants'

const initialState = {
	loading: false,
	web3: null,
	netwrok: true,
	coinbase: null,
	minted: 0,
	maxSupply: 0,
	cost: 0,
	revealed: false,
	paused: false,
	onlyWhitelisted: false,
	nftPerAddressLimit: 0,
	maxMintAmountPerTx: 1,
}

export default function User(state = initialState, action) {
  switch( action.type ) {

		case USER_UPDATE:
			return {
				...state,
				...action.data
			}

    default:
			return state
  }

}
