import React, {useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'


import {Countdown, Incrementor} from '../../../Elements'

import {DISCORD_URL} from '../../../../constants'

import {
	tryConnectWeb3,
	mintNFT,
} from '../../../../actions/user'

var moment = require('moment-timezone');

export default function Header(props) {

	const dispatch = useDispatch()
	const user = useSelector(state => state.user)

	const [end, setEnd] = useState(false)
	const [amount, setAmount] = useState(1)

	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}

	const onConnect = () => dispatch(tryConnectWeb3())

	const onMint = () => dispatch(mintNFT(amount))


	return(
		<div id='header'>

			{end ? 
				<>
					<h1>MINT LIVE <br /> {user.minted} / {user.maxSupply}</h1>
				</>
				:
				<h1>Comming Soon</h1>
			}

			{end ? 
				<div className='mint-section'>
					
					<Incrementor 
						onChange={e => setAmount(e)}
						value={amount}
						min={1}
						max={user.maxMintAmountPerTx || 1} 
					/>

				</div>
			:
				<Countdown deadline={moment('2022-02-01 14:00:00')} onEnd={() => setEnd(true)} />
			}
		{/* deadline={moment('2022-02-01 14:00:00')} */}
			<div className='buttons-group'>

				{	user.coinbase && end ?
					<button className='btn btn-connect' disabled={!end} onClick={onMint}>
						Mint {amount} ( {parseFloat(user.cost * amount).toFixed(2)} ETH )
					</button>
				:
					<button className='btn btn-connect' disabled={!end} onClick={onConnect}>
						Connect Metamask
					</button>
				}

				<button className='btn btn-join' onClick={() => openInNewTab(DISCORD_URL)}>
					 Join Discord
				</button>

			</div>


			<div className='images-cover'>

				<img src='assets/img/2.png' className='img-1' alt=''/>
				<img src='assets/img/1.png' className='img-2' alt=''/>
				<img src='assets/img/3.png' className='img-3' alt=''/>
				<img src='assets/img/4.png' className='img-4' alt=''/>
				<img src='assets/img/7.png' className='img-5' alt=''/>

				<div className='blur' />

			</div>

	

		</div>
	)

}