import React from 'react'

export default function Incrementor(props) {


	const onInvrement = () => {

		if (props.max && props.value >= props.max)
			return false

		let newValue = props.value  + 1

		props.onChange(newValue)

	}

	const onDecrement = () => {

		if (props.min && props.value <= props.min)
			return false

		let newValue = props.value  - 1

		props.onChange(newValue)
		

	}

	return(
		<div className='incrementor'>

			<button className='incrementor-btn' onClick={onDecrement}>
				<i className="fas fa-minus" />
			</button>

			<input type='number' disabled value={props.value} />

			<button className='incrementor-btn' onClick={onInvrement}>
				<i className="fas fa-plus" />
			</button>

		</div>
	)

}