
import React, {useEffect, useState} from 'react'

var moment = require('moment-timezone')

moment.tz.setDefault('Europa/London');
let utcTime = moment.tz('2022-02-01T14:00:00+00:00', 'Europa/London') 


export default function Countdown(props) {

	const [day, setDay] = useState(0)
	const [hours, setHours] = useState(0)
	const [minutes, setMinutes] = useState(0)
	const [seconds, setSeconds] = useState(0)

	const getTimeRemaining = endtime => {

		let now =  moment().tz('Europe/London')
		var duration = moment.duration(endtime.diff(now));
	
		var t = duration.asSeconds()
	
		var seconds = Math.floor((t) % 60);
		var minutes = Math.floor((t  / 60) % 60);
		var hours = Math.floor((t / (60 * 60)) % 24);
		var days = Math.floor(t / (60 * 60 * 24));
		
		return {
			'total': t,
			'days': days,
			'hours': hours,
			'minutes': minutes,
			'seconds': seconds
		}
	
	}


	const	initializeClock = endtime => {

		const updateClock = () => {
			var t = getTimeRemaining(endtime);
	
			setDay(('0' + t.days).slice(-2))
			setHours(('0' + t.hours).slice(-2))
			setMinutes(('0' + t.minutes).slice(-2))
			setSeconds(('0' + t.seconds).slice(-2))
	
			if (t.total <= 0) {
				clearInterval(timeinterval);
				props.onEnd()
			}
		}
	
		updateClock();
		var timeinterval = setInterval(updateClock, 1000)

	}


	useEffect(() => {

		initializeClock(utcTime)
		// eslint-disable-next-line
	},[utcTime])


	return(
		<div className='countdown'>
			{props.title ? <h1>{props.title}</h1> : ''}

			<div className="clock">
				<div>
					<div className="days timmer">{day}</div>
					<div className="smalltext">Days</div>
				</div>
				<div>
					<div className="hours timmer">{hours}</div>
					<div className="smalltext">Hours</div>
				</div>
		
				<div>
					<div className="minutes timmer">{minutes}</div>
					<div className="smalltext">Minutes</div>
				</div>
			
				<div>
					<div className="seconds timmer">{seconds}</div>
					<div className="smalltext">Seconds</div>
				</div>
			</div>

			<h3 className='text-muted'>{utcTime.format('DD MMM YYYY HH:mm:ss')} UTC</h3>

		</div>
	)

}