import Web3Modal from "web3modal";
import Web3 from "web3"
import { 
	showMessage,
	showPromiseMessage
} from "../utils";

import {
	NETWORKS,
	ALLOWED_NETWORKS,

	USER_UPDATE,
	CONTRACT_ADDRESS

} from '../constants'

import abi from "../utils/abis.js"

/************************************************
* Method for update user redux
* @param {object} data
************************************************/

export const updateUserData = data => dispatch => dispatch({ type: USER_UPDATE, data: data })


/************************************************
* Method for chainging the Chain
************************************************/

export const detectChain = () => async (dispatch, getState) => {

	// check if metamask exist
	if (!window.ethereum) 
		return showMessage('You don\'t hame Metamask on your Browser!')	

	// listen for netwrok change
	window.ethereum.on('chainChanged', async () => {

		dispatch(tryConnectWeb3())
	
	})

}


/************************************************
* Method for chainging the Chain
************************************************/

export const changeChain = networkName => async (dispatch) => {

	try {

		// check if metamask exist
		if (!window.ethereum) 
			throw new Error('You don\'t hame Metamask on your Browser!')

		await window.ethereum.request({
			method: 'wallet_addEthereumChain',
			params: [
				{ 
					...NETWORKS[networkName]
				}
			]
		})	


	} catch (err) {
		console.log('aaa',err)
	}

}


/************************************************
* Method for Web3 Connection
************************************************/

export const tryConnectWeb3 = () => async ( dispatch ) => {

	// init web3 modal
	const providerOptions = {};
	const web3Modal = new Web3Modal({
		network: "mainnet",
		cacheProvider: true,
		theme: "dark",
		providerOptions
	});

	//connect to provider
	const provider = await web3Modal.connect().catch(e => showMessage(e.message))

	if (!provider)
		return false

	// init web3
	const web3 = new Web3(provider)

	// get chain ID
	let chainId = await web3.eth.net.getId()

	// get user address
	let address = await web3.eth.getAccounts().then(e => e[0].toLowerCase())

	// update user data
	await dispatch(updateUserData({
		network: ALLOWED_NETWORKS.includes(`0x${Number(chainId).toString(16)}`) ? true : false,
		coinbase: address,
		web3: web3
	}))


	dispatch(fetchContractDetails())

}



/************************************************
* Method for fetching inventory items
************************************************/

export const fetchContractDetails = () => async (dispatch, getState) => {

	const {user} = getState()

	if (!user.network)
		return showMessage('Contract not deployed on this Network, change to ETH Network!')
	

	// init contracts
	var mainContract = new user.web3.eth.Contract(abi.main ,CONTRACT_ADDRESS)

	let minted = await mainContract.methods.totalSupply().call()
	let maxSupply =	 await mainContract.methods.maxSupply().call()
	let cost = await mainContract.methods.cost().call()
	let revealed = await mainContract.methods.revealed().call()
	let paused = await mainContract.methods.paused().call()
	let onlyWhitelisted = await mainContract.methods.onlyWhitelisted().call()
	let nftPerAddressLimit = await mainContract.methods.nftPerAddressLimit().call()
	let maxMintAmountPerTx = await mainContract.methods.maxMintAmountPerTx().call()


	// update user data
	await dispatch(updateUserData({
		minted: parseInt(minted),
		maxSupply: parseInt(maxSupply),
		cost: parseFloat(user.web3.utils.fromWei(cost, "ether")),
		revealed: revealed,
		paused: paused,
		onlyWhitelisted: onlyWhitelisted,
		nftPerAddressLimit: parseInt(nftPerAddressLimit),
		maxMintAmountPerTx: parseInt(maxMintAmountPerTx)
	}))

}


/************************************************
* Method for mining nft
* @param {number} amount
************************************************/

export const mintNFT = amount => async (dispatch, getState) => {

	// get user
	const {user} = getState()

	// check newtork
	if (!user.network || isNaN(amount))
		return false

	// init contracts
	var mainContract = new user.web3.eth.Contract(abi.main ,CONTRACT_ADDRESS)

	let process = new Promise((resolve, reject) => {
		mainContract.methods.mint(amount).send({
			from: user.coinbase,
			value: user.web3.utils.toWei(String(user.cost * amount), "ether"),
		}).then((data) => resolve(data))
		.catch((e) => {return reject(e.message)})
	})
	
	showPromiseMessage(process)

}

