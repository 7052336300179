import React from 'react'
import {TWITTER_URL, DISCORD_URL} from '../../constants'

export default function Footer(props) {

	return(
		<div className='footer-page'>
			<div className='max'>

				<div className="socials">
					<a href={TWITTER_URL} target="_blank"  rel="noreferrer"><i className="fab fa-twitter"/> </a>
					<a href={DISCORD_URL} target="_blank"  rel="noreferrer"><i className="fab fa-discord"/> </a>
				</div>

				<div className='terms'>
					<a href='/' target="_blank"  rel="noreferrer">Privacy Policy</a>
					<a href='/' target="_blank"  rel="noreferrer">Terms Of Use</a>
					<a href='https://etherscan.io/address/0x716d19ce79538b2ec056271043ae4caee480f272' target="_blank"  rel="noreferrer">Smart Contract</a>
				</div>

				<div className='copyright'>© Copyrights 2022. Blady NFT. All rights reserved.</div>
				
			</div>
		</div>
	)

}